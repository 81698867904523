.progressive-loading-wrapper {
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden
}

.progressive-loading-wrapper {
  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }

  .thumb {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 1;
    object-fit: cover;
    filter: blur(16px);
    transform: scale(1.25);
    transition: opacity 0.3s ease-in-out;

    &.hide {
      opacity: 0;
      transform: scale(1);
    }
  }

  .original {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
