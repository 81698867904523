/* latin */
@font-face {
  font-family: 'Baloo 2';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/baloo2/v1/wXKrE3kTposypRyd51jcAM4olXc.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@import '../node_modules/normalize.css/normalize.css';
@import "utilities/_variables";

/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Baloo 2', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #282c34;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  text-decoration: none;
  color:unset;
}

html, body {
  height: 100vh;
  overflow: hidden;
}

//html {
//  @include fluid-type($min_width, $max_width, $min_font, $max_font);
//}


.container {
  max-width: 1400px;
  margin: 0 auto;
  &.full-height {
    height: 100vh;
  }
}
