.main {

  &__background {
    z-index: -1;
    opacity: 0.4;
  }

  &__item {
    opacity: 0;
  }

 &__message {
   z-index: 1;
   position: absolute;
   display: flex;
   align-items: center;
   justify-content: center;
   width: 100vw;
   height: 100vh;
 }
}
